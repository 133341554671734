<template>
  <b-row>
    <b-col cols="12">
      <h4>Tutorials</h4>
      <b-row>
        <b-col cols="3">
          <b-card header="July 2024 Update">
            <p>As a part of our 2024 updates, we've made significant improvements to the Policy Design section!</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/3IILfuyIZ9s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Intake Complete Email Update">
            <p>At your requests, we've made an update to the Intake Completed email that is received by the agents, or teams, after a client finishes their Intake Request form. Watch and learn how to add the client email to this form!</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/3yHLiSBi85I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Future Debts Enhancement">
            <p>This is a follow up video to our original Future Debts feature. This enhancement gives you the ability to choose how to pay for the future debt, New Money (which was the original default) or to pull from Wind Current.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/nYDQpkuYS_I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="January 2021 Update">
            <p>In a final push to end 2020, we made some updates to the software!</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/QJp7-BGnZsg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Email Settings">
            <p>This is a short tutorial on how to edit the email settings on Inspiring Hope &amp; Freedom Software.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/RqvhCFiCoOA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Future Debts">
            <p>In this tutorial, I walk you through a new feature which allows you to add future debts to a client strategy. I also highlight the other updates we've made to simplify workflow and expand the level of details within the strategy itself.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/ACiWy1myWMM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="How to change your password">
            <p>This very short video shows you how to change your password in IHF.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/J0k9wdn7CWE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Basic Strategy Design">
            <p>This short introduction to strategy design will take you from the initial understanding of the inputs from the client, to actually creating a strategy in the software.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/8t6WJP6HGIY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="FULL walkthrough with Mike Everett">
            <p>In this video, Mike Everett takes you through a full walkthrough of the Inspiring Hope & Freedom software. This is a comprehensive video displaying both the simplicity and power of this new software.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/7ig5ouU-J_s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card header="Add a Client">
            <p>Brief description on how to easily add a client. Including screen shots of emails that agents and clients will receive from the software.</p>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/bm-olrqVoLo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'Tutorials',
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  watch: {

  },
  created () {

  }
}
</script>
